<template>
	<div>
		<el-dialog :title="!dataForm.meetId ? '监理会议登记' : '监理会议修改'" :close-on-click-modal="false" :visible.sync="visible"
			width="925px">
			<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="115px" class="border-form">
				<el-tabs v-model="activeIndex" type="card" @tab-click="handleTabClick">
					<el-tab-pane :label="item.label" :key="item.name" v-for="item in tabs" :name="item.name"></el-tab-pane>
				</el-tabs>
				<div>
					<div v-if="activeIndex == 'meetingBasic'">
						<el-row class="set_border">
							<el-col :span="24">
								<el-form-item label="标的物" prop="subjectNoList" class="item">
									<el-select style="margin-left:10px;width: 440px;" v-model="dataForm.subjectNoList" placeholder="" multiple clearable>
										<el-option v-for="item in subjectList" :key="item.displayValue"
											:label="item.displayName" :value="item.displayValue">
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row class="set_border">
							<el-col :span="24">
								<el-form-item label="会议编号" prop="meetNumber" class="item">
									<el-input class="selItemInput1" type="input" v-model="dataForm.meetNumber"
										placeholder="" style="margin-left: 10px;"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row class="set_border">
							<el-col :span="24">
								<el-form-item label="会议时间" class="item">
									<el-date-picker class="setItemInput2" v-model="dataForm.meetDate" type="date"
										value-format="yyyy-MM-dd" placeholder="选择日期" style="margin-left: 10px;">
									</el-date-picker>
									<el-time-picker class="setItemInput3" is-range v-model="dataForm.meetTimeSection"
										range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间"
										placeholder="选择时间范围" value-format="yyyy-MM-dd HH:mm:ss">
									</el-time-picker>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row class="set_border">
							<el-col :span="24">
								<el-form-item label="会议地点" class="item">
									<el-input class="selItemInput1" type="input" v-model="dataForm.meetPlace"
										style="margin-left: 10px;" placeholder=""></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row class="set_border">
							<el-col :span="24">
								<el-form-item label="会议主题" class="item">
									<el-input class="selItemInput1" type="input" v-model="dataForm.meetTitle"
										style="margin-left: 10px;" placeholder=""></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row class="set_border">
							<el-col :span="24">
								<el-form-item label="参加单位及人员" class="item1">
									<el-input type="textarea" class="selItemInput1" v-model="dataForm.particapants"
										style="margin-left: 10px;" placeholder=""></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row class="set_border">
							<el-col :span="16">
								<el-form-item label="会议描述" class="item">
									<el-input class="selItemInput1" v-model="dataForm.meetDescribe"
										style="margin-left: 10px;" placeholder=""></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<div style="color:#cccfce;margin-top: 15px;margin-left: 20px;">
									此项将作为监理周月报、日志时提取使用。
								</div>
							</el-col>
						</el-row>
						<el-row class="set_border">
							<el-col :span="8" class="col-set_border">
								<el-form-item label="记录人" class="item">
									<el-input class="selItemInput3" v-model="dataForm.meetRecorder"
										style="margin-left: 10px;" placeholder=""></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="时间" class="item">
									<el-date-picker v-model="dataForm.meetRecordDate" type="date" value-format="yyyy-MM-dd"
										placeholder="选择日期" class="selItemInput4">
									</el-date-picker>
								</el-form-item>
							</el-col>
						</el-row>
					</div>
					<div v-if="activeIndex == 'meetingMinute'">
						<el-row>
							<div v-for="(item, index1) in dataForm.meetItemList" :key="index1">
								<el-col :span="24">
									<el-form-item class="set_meetItem">
										<span slot="label">议题{{ index1 + 1 }}</span>
										<el-input class="item_input"
											v-model="dataForm.meetItemList[index1].itemContext"></el-input>
										<el-button v-preventReClick type="text" size="small" class="min_btn"
											@click="deleteMeetMin(index1)">删除</el-button>
									</el-form-item>
								</el-col>
								<el-col :span="24">
									<div v-for="(detail, index2) in dataForm.meetItemList[index1].detailList" :key="index2"
										class="delete_input_bar">
										<el-form-item style="margin-left: 40px;" class="set_meetItem">
											<span slot="label">子项{{ index2 + 1 }}</span>
											<el-input class="detail_input"
												v-model="dataForm.meetItemList[index1].detailList[index2].detailContext"></el-input>
											<el-button v-preventReClick type="text" size="small" class="min_btn"
												@click="deleteDetailExp(index1, index2)">删除</el-button>
										</el-form-item>
									</div>
								</el-col>
								<el-col :span="24" style="margin-bottom: 20px;">
									<el-button v-preventReClick type="text" size="small" style="margin-left: 94px;"
										@click="addDetailExp(index1)">添加子项</el-button>
								</el-col>
							</div>
							<el-col :span="24">
								<el-button v-preventReClick type="text" size="small" @click="addMeetMin()">添加议题</el-button>
							</el-col>
						</el-row>
					</div>
					<div v-if="activeIndex == 'meetingPhotos'">
						<el-row>
							<el-col :span="24">
								<el-form-item label="">
									<el-upload ref="uploadTempImg" class="upload-demo" action="action"
										style="margin-left: -115px;" :http-request="uploadTempImg" multiple drag
										:show-file-list=false :auto-upload="true" accept="image/jpeg,image/png,image/jpg">
										<i class="el-icon-upload"></i>
										<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
									</el-upload>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-table v-if="dataForm.photoRecords != undefined && dataForm.photoRecords.length > 0" border
									:data="dataForm.photoRecords" style="width: 100%;">
									<el-table-column type="index" label="序号" width="60" align="center">
									</el-table-column>
									<el-table-column label="图片" width="180" align="center">
										<template slot-scope="scope">
											<el-image style="height: 100px" :src="scope.row.photoFilePathUrl"
												fit='contain'></el-image>
										</template>
									</el-table-column>
									<el-table-column label="描述" width="500px" align="center">
										<template slot-scope="scope">
											<el-input type="textarea" rows="2"
												v-model="dataForm.photoRecords[scope.$index].photoContent"
												placeholder="请填写"></el-input>
										</template>
									</el-table-column>
									<el-table-column label=" " width="123">
										<template slot-scope="scope">
											<el-button size="mini" type="danger"
												@click="delPhoto(scope.$index)">删除</el-button>
										</template>
									</el-table-column>
								</el-table>
							</el-col>
						</el-row>
					</div>
					<div v-if="activeIndex == 'meetingSign'">
						<el-row>
							<el-col :span="24">
								<el-form-item label="会议签到表扫描件" class="sp-input">
									<div v-if="dataForm.meetTimesheet" style="margin-bottom: 35px;margin-left: 35px;">
										<el-button v-preventReClick type="success" size="small"
											@click="selectFile(dataForm.meetTimesheetUri)">查看</el-button>
										<el-button v-preventReClick type="primary" size="small"
											@click="delFile(1)">删除</el-button>
									</div>
									<el-upload ref="uploadFileTimesheet" class="upload-demo" action="action" drag
										v-if="!dataForm.meetTimesheet" :http-request="uploadFileTimesheet"
										:show-file-list=false :auto-upload="true" accept="image/jpeg,image/png,image/jpg"
										style="margin-left: 35px;">
										<i class="el-icon-upload"></i>
										<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
									</el-upload>
								</el-form-item>
							</el-col>
						</el-row>
					</div>
				</div>
				<el-row class="formBtnGroup">
					<el-button v-preventReClick class="btn" type="primary" size="small"
						@click="dataFormSubmit()">保存</el-button>
					<el-button v-preventReClick class="rebtn" size="small" @click="visible = false">取消</el-button>
				</el-row>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
import addImg from "@/assets/images/addImg.png"
import $common from "@/utils/common.js"
export default {
	name: "problem-add-or-update",
	data() {
		return {
			editorConfig: {
				UEDITOR_HOME_URL: "/UEditor/"
			},
			addImg: addImg,
			id: "",
			visible: true,
			dialogVisible: false,
			photoList: [],
			photoContent: '',
			addImg: addImg,
			form: {},
			action: "",
			meetingTypeList: [],
			subjectList:[],
			dataForm: {
				workNo: $common.getItem("workNo"),
				subjectNo: $common.getItem("subjectNo"),
				meetId: "",
				meetDate: "",
				sponsor: "",
				meetKind: "",
				meetRecorder: "",
				meetPlace: "",
				particapants: "",
				meetTitle: "",
				meetContent: "",
				meetMinutes: "",
				meetSummary: '',
				meetMinutesName: "",
				meetTimesheet: "",
				meetTimesheetName: "",
				meetDescribe: '',
				photoRecords: [],
				meetNumber: '',
				meetRecordDate: '',
				meetStartTime: '',
				meetEndTime: '',
				meetTimeSection: [],
				meetItemList: [],
			},
			activeIndex: 'meetingBasic',
			tabs: [{ name: 'meetingBasic', label: '会议基本情况' },
			{ name: 'meetingMinute', label: '会议纪要' },
			{ name: 'meetingPhotos', label: '会议照片' },
			{ name: 'meetingSign', label: '签到表扫描件' }
			],
			dataRule: {
				subjectNoList: [
					{ required: true, message: ' ', trigger: 'blur' }
				],
				meetNumber: [
					{ required: true, message: ' ', trigger: 'blur' }
				],
				meetTitle: [
					{ required: true, message: ' ', trigger: 'blur' }
				],

			},
		};
	},
	components: {

	},
	activated() {

	},
	methods: {
		handleTabClick(tab) {
			this.activeIndex = tab.name
		},
		delPhotoImg(index) {
			this.photoList.splice(index, 1);
		},
		delPhoto(index) {
			this.dataForm.photoRecords.splice(index, 1);
		},
		savePhotoList() {
			if (!this.photoContent) {
				this.$message.error('请填写照片描述');
				return;
			}
			if (!this.photoList || this.photoList.length === 0) {
				this.$message.error('请选择上传照片');
				return;
			}

			let item = {
				recNo: this.photoRecNo,
				photoContent: this.photoContent,
				photoFilePath: this.photoList[0].photoFilePath,
				photoFilePathUrl: this.photoList[0].photoFilePathUrl,
			};
			if (this.dataForm.photoRecords == null) {
				this.dataForm.photoRecords = []
			}
			this.dataForm.photoRecords.push(item);
			this.dialogVisible = false;
		},
		uploadTempImg(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadTempImg.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/95/0",
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data
				if (res.resultCode === 200) {
					this.dataForm.photoRecords.push({
						photoFilePath: res.body.fileSavePath,
						photoFilePathUrl: res.body.fileUri,
						photoContent: this.extractFileName(res.body.fileName)
					});
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		extractFileName(fileName) {
			let index = fileName.lastIndexOf(".");
			return fileName.substr(0, index)
		},
		selectFile(url) {
			window.open(url);
		},
		delFile(type) {
			if (type === 1) {
				this.dataForm.meetTimesheet = '';
				this.dataForm.meetTimesheetUri = '';
			} else {
				this.dataForm.meetMinutes = '';
				this.dataForm.meetMinutesUri = '';
			}
		},
		deleteMeetMin(index1) {
			this.dataForm.meetItemList.splice(index1, 1);
		},
		addMeetMin() {
			let meetMin = {
				itemContext: '',
				detailList: []
			}
			this.dataForm.meetItemList.push(meetMin);
		},
		deleteDetailExp(index1, index2) {
			this.dataForm.meetItemList[index1].detailList.splice(index2, 1);
		},
		addDetailExp(index1) {
			let detail = {
				detailContext: ''
			}
			this.dataForm.meetItemList[index1].detailList.push(detail)
		},
		init(id, subjectNo) {
			this.dataForm = {
				workNo: $common.getItem("workNo"),
				subjectNoList: [],
				meetNumber: '',
				meetId: "",
				meetDate: "",
				sponsor: "",
				meetKind: "",
				meetRecorder: "",
				meetPlace: "",
				particapants: "",
				meetTitle: "",
				meetContent: "",
				meetMinutes: "",
				meetMinutesName: "",
				meetSummary: '',
				meetTimesheet: "",
				meetTimesheetName: "",
				meetDescribe: '',
				photoRecords: [],
				meetNumbser: '',
				meetRecordDate: '',
				meetStartTime: '',
				meetEndTime: '',
				meetTimeSection: [],
				meetItemList: [{
					itemContext: '',
					detailList: []
				}],
			};
			this.dataForm.meetId = id ? id : 0;
			this.dataForm.meetRecorder = $common.getItem("userName");
			this.dataForm.meetRecordDate = this.getCurrentDate();
			this.dataForm.meetDate = this.getCurrentDate();
			this.activeIndex = 'meetingBasic'
			this.getSubjectDropDown();
			this.getMeetingType();
			if (id) {
				this.getDetail();
			} else {
				this.dataForm.meetTimeSection = [this.getCurrentTime(), this.getCurrentTime()]
			}
			this.$nextTick(() => {
				this.$refs['dataForm'].resetFields();
				this.visible = true;
				let subjectNoList = []
				subjectNoList.push(subjectNo)
				this.$set(this.dataForm, 'subjectNoList', subjectNoList)
			})
		},
		getSubjectDropDown() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.subjectList = data.body;
				}
			});
		},
		getCurrentDate() {
			//获取当前时间并打印
			let yy = new Date().getFullYear();
			let mm = new Date().getMonth() + 1;
			let dd = new Date().getDate();
			let hh = new Date().getHours();
			let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
			let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
			return yy + '-' + mm + '-' + dd;
		},
		getCurrentTime() {
			//获取当前时间并打印
			let yy = new Date().getFullYear();
			let mm = new Date().getMonth() + 1;
			let dd = new Date().getDate();
			let hh = new Date().getHours();
			let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
			let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
			return yy + '-' + mm + '-' + dd + " " + hh + ":" + mf + ":" + ss;
		},
		getMeetingType() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getMeetingType",
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.meetingTypeList = data.body;
				}
			});
		},
		getDetail() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/meetinfo/info/" + this.dataForm.meetId,
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataForm = data.body
					if (this.dataForm.photoRecords == null || this.dataForm.photoRecords == undefined) {
						this.dataForm.photoRecords = []
					}
					if (data.body.meetItemList == undefined || data.body.meetItemList.length <= 0 || data.body.meetItemList == null) {
						this.dataForm.meetItemList = [{
							itemContext: '',
							detailList: []
						}]
					}
					let meetStartTime = data.body.meetStartTime;
					let meetEndTime = data.body.meetEndTime;
					let meetTimeSection = [meetStartTime, meetEndTime]
					this.$set(this.dataForm, 'meetTimeSection', meetTimeSection)
				}
			});
		},
		uploadFileMinutes(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadFileMinutes.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/11/0",
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data
				if (res.resultCode === 200) {
					this.dataForm.meetMinutes = res.body.fileSavePath;
					this.dataForm.meetMinutesName = res.body.fileName;
					this.dataForm.meetMinutesUri = res.body.fileUri;
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		uploadFileTimesheet(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadFileTimesheet.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/11/0",
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data
				if (res.resultCode == 200) {
					this.dataForm.meetTimesheet = res.body.fileSavePath
					this.dataForm.meetTimesheetName = res.body.fileName
					this.dataForm.meetTimesheetUri = res.body.fileUri
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		dataFormSubmit() {
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					if (this.dataForm.meetNumber == '' || this.dataForm.meetNumber == undefined) {
						this.$message.error('请填写会议编号');
						return
					}

					let _url = "/business/meetinfo/save";
					if (this.dataForm.meetId && this.dataForm.meetId != 0) {
						_url = "/business/meetinfo/update";
					}
					if (this.dataForm.meetTimeSection != null) {
						this.dataForm.meetStartTime = this.dataForm.meetTimeSection[0] ? this.dataForm.meetTimeSection[0] : ""
						this.dataForm.meetEndTime = this.dataForm.meetTimeSection[1] ? this.dataForm.meetTimeSection[1] : ""
					} else {
						this.dataForm.meetStartTime = ""
						this.dataForm.meetEndTime = ""
					}

					if (this.dataForm.meetDate == '' || this.dataForm.meetDate == undefined) {
						this.$message.error('请填写会议日期');
						return
					}

					if (this.dataForm.meetStartTime == '' || this.dataForm.meetStartTime == undefined) {
						this.$message.error('请填写会议开始时间');
						return
					}

					if (this.dataForm.meetEndTime == '' || this.dataForm.meetEndTime == undefined) {
						this.$message.error('请填写会议结束时间');
						return
					}

					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData(this.dataForm)
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.visible = false;
									this.$emit("refreshDataList");
								}
							});
						}
					});
				}
			});
		},
	}
};
</script>

<style scoped>
.selItemInput1 {
	width: 440px !important
}

.setItemInput2 {
	width: 200px !important
}

.selItemInput3 {
	width: 140px !important;
}

.selItemInput4 {
	width: 162px !important;
}

.setItemInput3 {
	width: 220px !important;
	margin-top: -5px !important;
	margin-left: 20px !important;
}

.set_meetItem /deep/ .el-form-item__label {
	margin-left: 2px !important;
	width: 80px !important;
	margin-bottom: 10px;
	background-color: #E1F1FF;
	color: #00428E !important;
	font-weight: bold !important;
}

.item_input /deep/ .el-input__inner {
	width: 690px !important;
	margin-left: -34px;
}

.detail_input /deep/ .el-input__inner {
	width: 650px !important;
	margin-left: -34px;
}

.selItemInput.descAreaLg {
	width: 800px !important;
}

.addImgBox {
	width: 136px;
	height: 136px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}

.upload-demo /deep/ .el-upload-dragger {
	width: 300px;
	height: 100px;
}

.upload-demo /deep/ .el-upload-dragger .el-icon-upload {
	font-size: 40px;
	margin-top: 15px;
	line-height: 26px;
}

.upload-demo /deep/ .el-upload-dragger .el-upload__text {
	line-height: 8px;
}

.sp-input /deep/ .el-form-item__label {
	width: 135px !important;
	background-color: #E1F1FF;
	color: #00428E !important;
	font-weight: bold !important;
}

::v-deep(.el-table th.el-table__cell) {
	background-color: #d3e2f4 !important;
	font-size: 15px;
	padding: 2px 0;
}

::v-deep .el-tabs__item.is-active {
	color: #1d1d86;
	background-color: #daeafc;
}

::v-deep .el-tabs__item:hover {
	color: #121213;
	background-color: #eceeed;
}

::v-deep .el-tabs--top .el-tabs__item.is-top {
	width: 150px;
	text-align: center;
}

.min_btn {
	margin-left: -50px;
	position: absolute;
}

.border-form .el-row.set_border {
	border-bottom: solid #dadada 1px;
	border-left: solid #dadada 1px;
	border-right: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
	border-top: solid #dadada 1px;
}

::v-deep .el-form-item {
	/* display: flex !important; */
	align-items: stretch !important;
	margin-bottom: 0px !important;
}

.item::v-deep .el-form-item__label {
	color: #00428E !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border-right: solid #dadada 1px;
}

.item1::v-deep .el-form-item__label {
	color: #00428E !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border-right: solid #dadada 1px;
	height:55px;
	padding-top:8px;
}

.border-form .el-row.set_border .col-set_border {
	border-right: 1px solid #dadada;
}
</style>
